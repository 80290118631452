import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";
import * as datasource from "../../grid/datasource";
import * as gridUtils from "../../grid/utils";
import { ColumnsState } from "grid/columns_state";

interface ReviewBatch {
  amount: number;
  count: number;
  created_at: string;
  number: number;
  payments_path: string;
  type: string;
  updated_at: string;
  user_id: number;
}

interface CtrlCallbacks {
  onRowClicked(event: AgGrid.RowClickedEvent<ReviewBatch, any>): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { indexPath, i18nJson, paginationPageSize, typesJson } = $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "number", ...columns.NUMBER_COL_DEF, ...columns.NUMBER_FILTER_COL_DEF, sortable: true },
    {
      field: "type",
      ...columns.DEFAULT_COL_DEF,
      ...columns.buildEnumColDef(JSON.parse(typesJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(typesJson)),
      sortable: true,
    },
    { field: "user_id", ...columns.DEFAULT_COL_DEF, ...columns.TEXT_FILTER_COL_DEF, sortable: true },
    { field: "count", ...columns.NUMBER_COL_DEF, ...columns.NUMBER_FILTER_COL_DEF, sortable: true },
    { field: "amount", ...columns.AMOUNT_COL_DEF, ...columns.AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "created_at",
      ...columns.DATE_COL_DEF,
      ...columns.DATE_FILTER_UNTIL_NOW_COL_DEF,
      sortable: true,
      sort: "asc",
    },
    { field: "updated_at", ...columns.DATE_COL_DEF, ...columns.DATE_FILTER_UNTIL_NOW_COL_DEF, sortable: true },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, indexPath, limit: paginationPageSize });

  const options: AgGrid.GridOptions<ReviewBatch> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef,
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    localeText: { ...columns.FILTER_TRANSLATIONS },
    datasource: dataSource,
    // events
    onColumnResized(event) {
      columnsState.onResize(event);
    },
    onRowClicked: ctrlCallbacks.onRowClicked,
  };
  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, "next_allocations_review_batches");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;

  gridApi: AgGrid.GridApi;

  connect() {
    if (this.hasGridTarget) {
      const $paymentsGrid: HTMLFormElement = document.querySelector("#review-batch-payments");
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");

      this.gridApi = buildGrid(
        this.gridTarget,
        {},
        {
          onRowClicked(event) {
            $paymentsGrid.src = event.data.payments_path;
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }
}
