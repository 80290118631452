import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as datasource from "../../grid/datasource";
import * as gridUtils from "../../grid/utils";
import { ColumnsState } from "grid/columns_state";

import { ACTIONS_COL_DEF, DATE_FILTER_COL_DEF, DEFAULT_COL_DEF, TEXT_FILTER_COL_DEF } from "../../grid/columns";

interface UserAuditTrail {
  date: string;
  event: string;
  item_type: string;
  initiator: string;
}

interface CtrlCallbacks {
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { i18nJson, paginationPageSize } = $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "executed", colId: "executed", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true },
    { field: "event", colId: "event", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "item_type", colId: "item_type", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "initiator", colId: "initiator", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    {
      field: "actions",
      ...ACTIONS_COL_DEF,
      headerComponentParams: { displayName: "" },
    },
  ];

  const dataSource = new datasource.Datasource({
    callbacks: dataCallbacks,
    limit: paginationPageSize,
  });

  const options: AgGrid.GridOptions<UserAuditTrail> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    defaultColDef: {
      ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<UserAuditTrail, any>),
    },
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    datasource: dataSource,
    cacheBlockSize: parseInt(paginationPageSize),
    pagination: true,
    paginationPageSize: parseInt(paginationPageSize),
    onColumnResized(event: AgGrid.ColumnResizedEvent) {
      const columnsState = new ColumnsState(event.api, "user_audit_trails");
      columnsState.onResize(event);
    },
    context: {
      i18n: JSON.parse(i18nJson),
    },
  };

  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;

  const columnsState = new ColumnsState(gridApi, "user_audit_trails");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });

  return gridApi;
}

export default class extends Controller {
  static targets = ["grid"];

  declare readonly gridTarget: HTMLElement;
  declare readonly hasGridTarget: boolean;

  gridApi: AgGrid.GridApi;

  connect() {
    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement | null = this.element.querySelector(".reset-grid");

      this.gridApi = buildGrid(
        this.gridTarget,
        {},
        {
          subscribeOnResetColumnWidths(callback: () => void) {
            if ($resetGridBtn) {
              $resetGridBtn.addEventListener("click", callback);
            }
          },
        },
      );
    }
  }

  backToPreviousPage(event: Event) {
    window.history.back();
  }

  disconnect() {
    this.gridApi?.destroy();
  }
}
